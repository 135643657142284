import Constants from '../../config.local.js'
import Common from '@/assets/js/common.js'
import _uniq from 'lodash/uniq'
import { EventBus } from 'EventBus'
var PaymentMixin = {
	data() {
		return {

		}
	},
	methods: {
		check_display_payment_form: function(params){
			this.checkPaymentFormFunction(params)
		},
		//Check si j'ai le droit d'ajouter un reglement
		checkPaymentForm(params){
			let invoice_ids = params.invoice_ids.split(",")
			let invoice_nums = params.invoice_nums.split(",")

			invoice_nums = invoice_nums.filter((num) => {
				return num != ""
			})

			if(invoice_ids.length != invoice_nums.length) {
				this.failureToast('payment.error_popup_add_payment_no_num_invoice')
				return false
			}

			// si j'ai des ttc négatifs et positifs => erreur
			const neg = params.invoice_ttc.filter(invoice => invoice < 0).length
			const pos = params.invoice_ttc.filter(invoice => invoice >= 0).length
			if(neg > 0 && pos > 0) {
				this.failureToast('payment.solde_compte_courant')
				return false
			}

			//On l'enleve car on ne peut pas solder une facture et un avoir avec un solde à 0
			// if(params.invoice_balances == 0)
			// {
			// 	this.failureToast('payment.error_popup_add_payment_no_balance_invoice')
			// 	return false
			// }

		    
	       	let tiers = params.invoice_tiers.split(",")

		    const uniqueTiers = Array.from(new Set(tiers))
		    if(uniqueTiers.length == 1) {
		        this.invoice_ids = params.invoice_ids.split(",")
		        this.invoice_nums = params.invoice_nums.split(",")
		        this.invoice_accountingplans = _uniq(params.invoice_accountingplans.split(","))
		        this.invoice_balances = params.invoice_balances

				if(this.tiers_id == "0") {
					this.tiers_id = uniqueTiers[0]
				}
		        return true
		    }
		    else {
		        this.failureToast('payment.error_popup_add_payment')
		        return false
		    }
		},

		check_unpaid_payment: async function(params){

			const url = Constants.PAYMENTS_SET_UNPAID_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const params_ = { payment_ids: params.payment_ids.split(",") }
			const result = await this.$request.request_post_api("PaymentMixin::check_unpaid_payment", url, params_, false)
			.catch(error => {
				console.error("PaymentMixin::check_unpaid_payment => ERROR", error)
				EventBus.$emit("TableAction::stopSpin", "goToUnpaidPayment")
				return false
			})

			EventBus.$emit("TableAction::stopSpin", "goToUnpaidPayment")
			this.successToast("payment.payment_unpaid")
			this.loadPayments()
			return result.retour

		},

		check_cancel_payment: async function(params){

			let isLinked = await this.isPaymentLinkedWithCheckDeposit(params.payment_ids)

			if(isLinked === false)
			{
				//Je peux dé-associer le reglement pour le supprimer
				const url = Constants.PAYMENTS_DELETE_URL + "?licence_key="+Constants.USER_LICENCE_KEY
				const params_ = { payment_ids: params.payment_ids.split(",") }
				const result = await this.$request.request_delete_api("PaymentMixin::check_cancel_payment", url, params_)
				.catch(error => {
					console.error("PaymentMixin::check_cancel_payment => ERROR", error)
					EventBus.$emit("TableAction::stopSpin", "goToCancelPayment")
					return false
				})

				EventBus.$emit("TableAction::stopSpin", "goToCancelPayment")
				this.successToast("payment.payment_deleted")
				this.loadPayments()
				return result.retour
			}
			else
			{
				EventBus.$emit("TableAction::stopSpin", "goToCancelPayment")
				this.failureToast("payment.payment_cashin_no_delete")
			}

		},

		isPaymentLinkedWithCheckDeposit: async function(payment_ids){

			const url = Constants.PAYMENTS_LINKED_CHECK_DEPOSIT_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const params = { payment_ids: payment_ids.split(",") }
			const result = await this.$request.request_post_api("PaymentMixin::isPaymentLinkedWithCheckDeposit", url, params, false)
			.catch(error => {
				console.error("PaymentMixin::isPaymentLinkedWithCheckDeposit => ERROR", error)
				return false
			})
			
			return result.retour.has

		},

		createPayment: async function(params) {
			const url = Constants.PAYMENTS_CREATE_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("PaymentMixin::createPayment", url, params, false, {
				syncOptional: ['invoice', 'payment', 'current_account']
			})
			.catch(error => {
				console.error("PaymentMixin::createPayment => ERROR", error)
				if (!error.response || !error.response.data || error.response.data.type !== 'ApiException') {
					this.failureToast("toast.info_save_failed")
				}

				return null
			})
			
			return result
		},
		
		listPayment: async function(params, from=null, to=null) {
			from = from ? from.toDateInputValue() : ''
			to = to ? to.toDateInputValue() : ''

			const url = `${this.constructRoute(Constants.PAYMENTS_URL, {})}?licence_key=${Constants.USER_LICENCE_KEY}&from=${from}&to=${to}`
			const result = await this.$request.request_get_api("PaymentMixin::listPayment", url, params, false)
			.catch(error => {
				console.error("PaymentMixin::listPayment => ERROR", error)
				return null
			})
			
			if(result) return result.retour
			return null
		},

		listRegulPayment: async function(params, from=null, to=null) {
			from = from ? from.toDateInputValue() : ''
			to = to ? to.toDateInputValue() : ''

			const url = `${this.constructRoute(Constants.PAYMENTS_REGUL_URL, {})}?licence_key=${Constants.USER_LICENCE_KEY}&from=${from}&to=${to}`
			const result = await this.$request.request_get_api("PaymentMixin::listRegulPayment", url, params, false)
			.catch(error => {
				console.error("PaymentMixin::listRegulPayment => ERROR", error)
				return null
			})
			
			if(result) return result.retour
			return null
		},
		
		listCheckDeposit: async function(params, from=null, to=null) {
			from = from ? from.toDateInputValue() : ''
			to = to ? to.toDateInputValue() : ''

			const url = `${this.constructRoute(Constants.CHECK_DEPOSIT_URL, {})}?licence_key=${Constants.USER_LICENCE_KEY}&from=${from}&to=${to}`
			const result = await this.$request.request_get_api("PaymentMixin::listCheckDeposit", url, params, false)
			.catch(error => {
				console.error("PaymentMixin::listCheckDeposit => ERROR", error)
				return null
			})
			
			if(result) return result.retour
			return null
		},
		addCheckDeposit: async function(params) {
			const url = Constants.CHECK_DEPOSIT_CREATE_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("PaymentMixin::addCheckDeposit", url, params, false)
			.catch(error => {
				console.error("PaymentMixin::addCheckDeposit => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})
			
			return result
		},
		deleteCheckDeposit: async function(params) {
			const url = Constants.CHECK_DEPOSIT_CREATE_URL + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_delete_api("PaymentMixin::deleteCheckDeposit", url, { checkdeposit_ids: params.checkdeposit_ids.split(',') })
			.catch(error => {
				console.error("PaymentMixin::deleteCheckDeposit => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})
			
			return result
		},
		accounted_payments: async function (payment_ids) {
			const params = {
				payment_ids: payment_ids
			}

			const url = Constants.PAYMENTS_ACCOUNTED_URL + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("InvoiceMixin::accounted_payments", url, params, false)
			.catch(error => {
				console.error("InvoiceMixin::accounted_payments => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})
			return result
		},
		cashCheckDeposit: async function(params) {
			const url = Constants.CHECK_DEPOSIT_CASH_URL + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("PaymentMixin::cashCheckDeposit", url, { checkdeposit_ids: params.checkdeposit_ids.split(',') }, false)
			.catch(error => {
				console.error("PaymentMixin::cashCheckDeposit => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})
			
			return result
		},
		
		loadAllCheck: async function(accountingplan_id, payment_method, start, end) {
			const url = this.constructRoute(Constants.PAYMENTS_UNDEPOSIT_URL, {accountingplan_id: accountingplan_id, payment_method: payment_method}) + "?licence_key="+Constants.USER_LICENCE_KEY+'&start='+start+'&end='+end
			const result = await this.$request.request_get_api("PaymentMixin::loadAllCheck", url, {}, false)
			.catch(error => {
				console.error("PaymentMixin::loadAllCheck => ERROR", error)
				return null
			})
			
			if(result) return result.retour
			return []
		},

		printCheckDeposit: async function(params, preview = false){

			const url = Constants.CHECK_DEPOSIT_PRINT_URL + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("PaymentMixin::printCheckDeposit", url, { checkdeposit_ids: params.checkdeposit_ids.split(',') }, false)
			.catch(error => {
				console.error("PaymentMixin::printCheckDeposit => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})

			if(!preview) {
				Common.base64ToPdf(result, "remise_cheque.pdf")
			}

			return result
		},

		loadExportPayment: async function(payment_ids) {
			const url = Constants.PAYMENT_EXPORT + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("PaymentMixin::loadExportPayment", url, {params: {payment_ids: payment_ids}}, false)
			.catch(error => {
				console.error("PaymentMixin::loadExportPayment => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})

			// if(result.has_ignored_paiement)
			// {
			// 	this.failureToast("toast.payment_not_exported_not_checkdeposit")
			// }

			Object.keys(result.export).forEach(function (item) {
				Common.csvToFile(result.export[item], "export_reglement_" + item + ".csv")
			});

		},

		saveEditPayment: async function(payment_id, params) {
			const url = this.constructRoute(Constants.PAYMENTS_EDIT_URL, {payment_id: payment_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("PaymentMixin::saveEditPayment", url, params, false)
			.catch(error => {
				console.error("PaymentMixin::saveEditPayment => ERROR", error)
				return null
			})
			
			if(result) return result.retour
			return null
		},
		
		async formatPayments(payments) {
			let paymentsFormated = []

			if(!payments)
			{
				return []
			}

			for (let i = 0; i < payments.length; i++) {
				const temp = payments[i]
				temp.entity = temp.accounting_plan.accountingplan_label
				temp.href = "tiersFiche"
				temp.params = { tiers_id: temp.tiers.tiers_id }
				temp.payment_unpaid = this.getTrad("payment.unpaid_" + temp.payment_unpaid)
				temp.invoices_num = this.getArrayObjProperty(temp.invoices, "invoice_num")
				temp.invoices_balance = this.getArrayObjProperty(temp.invoices, "invoice_balance")
				temp.client_account_numbers = ''
				temp.method.paymentmethod_label = this.getTrad('payment.payment_method_labels.' + temp.method.paymentmethod_label.replaceAll(' ', '_').toLowerCase())

				for(let x = 0; x < temp.invoices.length; x++ ){
					if(temp.tiers.tiers_client_account){
						let client_account = temp.tiers.tiers_client_account.filter(account => account.tiersclientaccount_accountingplan === temp.invoices[x].invoice_accountingplan)
						temp.client_account_numbers += client_account[0]['tiersclientaccount_number'] + ', '
					}
				}
				if (temp.client_account_numbers != ''){
					temp.client_account_numbers = temp.client_account_numbers.slice(0, -2)
				}
				temp.formatted_invoices = temp.invoices

				if(temp.check_deposit) {
					temp.check_deposit_formatted = temp.check_deposit.checkdeposit_ref + ' (' + this.getTrad(temp.check_deposit_status.checkdepositstatus_label) + ')'
				}
				else {
					temp.check_deposit_formatted = null
				}

				temp.contracts = []
				if(temp.invoices && temp.invoices.length > 0) {
					temp.invoices.forEach(invoice => {
						if(invoice.details) {
							invoice.details.forEach(detail => {
								if(detail.contract && !temp.contracts.find(c => c.contract_id == detail.contract.contract_id)) {
									temp.contracts.push({
										contract_id: detail.contract.contract_id,
										contract_num: detail.contract.contract_num
									})
								}
							})
						}
					})
				}

				paymentsFormated.push(temp);
			}

			return paymentsFormated
		},

		async loadCurrentAccount(tiers_id, keys_id = false){
			const url = this.constructRoute(Constants.TIERS_CURRENT_ACCOUNT_URL, {tiers_id: tiers_id, keys_id: keys_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("PaymentMixin::loadCurrentAccount", url, {}, false)
			.catch(error => {
				console.error("PaymentMixin::loadCurrentAccount => ERROR", error)
				return null
			})
			
			if(result) return result.retour
			return []
		},

		markAsRegulated: async function(payment_ids) {
			const url = this.constructRoute(Constants.PAYMENTS_REGUL_URL, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("PaymentMixin::markAsRegulated", url, {payment_ids: payment_ids}, false)
			.catch(error => {
				console.error("PaymentMixin::markAsRegulated => ERROR", error)
				return null
			})
			
			if(result) return result.retour
			return null
		},

		async loadHorseUnpaidByInvoices(invoices_ids) {
			const url = this.constructRoute(Constants.HORSE_UNPAID_BY_INVOICES, {invoices_ids}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("PaymentMixin::loadHorseUnpaidByInvoices", url, {}, false)
			.catch(error => {
				console.error("PaymentMixin::loadHorseUnpaidByInvoices => ERROR", error)
				return null
			})
			
			if(result) return result.retour
			return []
		},

		loadPaymentMethod: async function(paymentmethod_id) {
			const payment_methods = this.getConfig('payment_method')
			for (let i = 0; i < payment_methods.length; i++) {
				if(paymentmethod_id == payment_methods[i].paymentmethod_id) {
					return payment_methods[i]
				}
			}

			return null
		},

		loadPaymentsFromBank: async function(csv) {
			const url = this.constructRoute(Constants.PAYMENTS_INIT_FROM_BANK_URL, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("PaymentMixin::loadPaymentsFromBank", url, {csv: csv}, false)
			.catch(error => {
				console.error("PaymentMixin::loadPaymentsFromBank => ERROR", error)
				return null
			})
			
			if(result) return result.retour
			return null
		},

		savePaymentsFromBank: async function(data) {
			const url = this.constructRoute(Constants.PAYMENTS_CREATE_FROM_BANK_URL, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("PaymentMixin::savePaymentsFromBank", url, {data: data}, false)
			.catch(error => {
				console.error("PaymentMixin::savePaymentsFromBank => ERROR", error)
				return null
			})
			
			if(result) return result.retour
			return null
		},

		soldeInvoiceFromCC: async function(invoices_ids, accountingplan_id) {
			const url = Constants.INVOICE_SOLDE_CC_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("PaymentMixin::soldeInvoiceFromCC", url, {invoices_ids, accountingplan_id}, false)
			.catch(error => {
				console.error("PaymentMixin::soldeInvoiceFromCC => ERROR", error)
				return null
			})
			
			if(result) return result.retour
			return null
		},

		getCurrentAccountSupplier: async function (tiers_id){
			const url = this.constructRoute(Constants.INC_SUPPL_CURRENT_ACCOUNT, {tiers_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("PaymentMixin::getCurrentAccountSupplier", url, {}, false)
			.catch(error => {
				console.error("PaymentMixin::getCurrentAccountSupplier => ERROR", error)
				return null
			})
			
			if(result) return result.retour
			return []
		},

		getCurrentAccountDetailsSupplier: async function(tiers_id) {
			const url = this.constructRoute(Constants.INC_SUPPL_CURRENT_ACCOUNT_DETAILS, {tiers_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("PaymentMixin::getCurrentAccountDetailsSupplier", url, {}, false)
			.catch(error => {
				console.error("PaymentMixin::getCurrentAccountDetailsSupplier => ERROR", error)
				return null
			})

			if(result) return result.retour
			return []
		},

		createPaymentSupplier: async function(params) {
			const url = Constants.INC_PAYMENTS_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("PaymentMixin::createPaymentSupplier", url, params, false)
			.catch(error => {
				console.error("PaymentMixin::createPaymentSupplier => ERROR", error)
				if (!error.response || !error.response.data || error.response.data.type !== 'ApiException') {
					this.failureToast("toast.info_save_failed")
				}

				return null
			})
			
			return result
		},

		getInvoiceSupplierPaymentByTiers: async function(tiers_id) {
			const url = this.constructRoute(Constants.INC_PAYMENTS_TIERS_URL, {tiers_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("PaymentMixin::getInvoiceSupplierPaymentByTiers", url, {}, false)
			.catch(error => {
				console.error("PaymentMixin::getInvoiceSupplierPaymentByTiers => ERROR", error)
				return null
			})
			
			if(result) return result.retour
			return []
		},

		getInvoiceSupplierPayment: async function(start, end) {
			const url = Constants.INC_PAYMENTS_URL + "?licence_key="+Constants.USER_LICENCE_KEY+"&start="+start+"&end="+end
			const result = await this.$request.request_get_api("PaymentMixin::getInvoiceSupplierPayment", url, {}, false)
			.catch(error => {
				console.error("PaymentMixin::getInvoiceSupplierPayment => ERROR", error)
				return null
			})
			
			if(result) return result.retour
			return []
		},
		
		async formatPaymentsSupplier(payments) {
			let paymentsFormated = []

			if(!payments)
			{
				return []
			}

			for (let i = 0; i < payments.length; i++) {
				const temp = payments[i]
				temp.entity = temp.accounting_plan.accountingplan_label
				temp.href = "tiersFiche"
				temp.params = { tiers_id: temp.tiers.tiers_id }
				temp.ispayment_unpaid = this.getTrad("payment.unpaid_" + temp.ispayment_unpaid)
				temp.invoices_num = this.getArrayObjProperty(temp.invoices, "suppliersocr_num")
				temp.invoices_balance = this.getArrayObjProperty(temp.invoices, "suppliersocr_balance")
				temp.client_account_numbers = ''
				temp.method.paymentmethod_label = this.getTrad('payment.payment_method_labels.' + temp.method.paymentmethod_label.replaceAll(' ', '_').toLowerCase())

				for(let x = 0; x < temp.invoices.length; x++ ){
					if(temp.tiers.tiers_client_account){
						let client_account = temp.tiers.tiers_client_account.filter(account => account.tiersclientaccount_accountingplan === temp.accounting_plan.accountingplan_id)
						temp.client_account_numbers += client_account[0]['tiersclientaccount_number'] + ', '
					}
				}
				if (temp.client_account_numbers != ''){
					temp.client_account_numbers = temp.client_account_numbers.slice(0, -2)
				}
				temp.formatted_invoices = temp.invoices

				if(temp.check_deposit) {
					temp.check_deposit_formatted = temp.check_deposit.checkdeposit_ref + ' (' + this.getTrad(temp.check_deposit_status.checkdepositstatus_label) + ')'
				}
				else {
					temp.check_deposit_formatted = null
				}

				paymentsFormated.push(temp);
			}

			return paymentsFormated
		},

		saveEditPaymentSupplier: async function(payment_id, params) {
			const url = this.constructRoute(Constants.INC_PAYMENTS_ID_URL, {payment_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("PaymentMixin::saveEditPaymentSupplier", url, params, false)
			.catch(error => {
				console.error("PaymentMixin::saveEditPaymentSupplier => ERROR", error)
				return null
			})
			
			if(result) return result.retour
			return null
		},

		cancelPaymentSupplier: async function(payment_ids) {
			const url = this.constructRoute(Constants.INC_PAYMENTS_CANCEL_URL, {payment_ids: payment_ids.join(',')}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("PaymentMixin::cancelPaymentSupplier", url, null, false)
			.catch(error => {
				console.error("PaymentMixin::cancelPaymentSupplier => ERROR", error)
				return null
			})
			
			if(result) return result.retour
			return null
		}
	}
}

export default PaymentMixin
